<template>
  <div>
    <div v-if="isLoading">
      <v-card width="100vw" height="90vh" elevation="0">
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <v-progress-circular
                :width="5"
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <div v-if="loadingStatus == ''">
                Cargando ...
              </div>
              <div v-else>
                {{ loadingStatus }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div v-else class="pa-10">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined text @click="showAddContactModalF()">
          Agregar asesor
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        dark
        class="elevation-4 black--text"
        hide-default-footer
        :headers="headers"
        :items="contactsList"
        :items-per-page="contactsList.length"
      >
        <template v-slot:body="props">
          <tr v-for="(item, index) in props.items" :key="index">
            <td
              :class="item.status === 0 ? 'red lighten-4 pa-2' : 'white pa-2'"
            >
              <v-col class="ma-0 pa-0">
                <v-avatar>
                  <v-img :src="getProfileImg(item.media)" :alt="item.name">
                  </v-img>
                </v-avatar>
              </v-col>
              <v-col>
                <div v-if="item.status === 0" class="grey--text caption">
                  (Eliminado)
                </div>
              </v-col>
            </td>
            <td
              :class="item.status === 0 ? 'red lighten-4 pa-2' : 'white pa-2'"
            >
              <div v-if="item.name">
                {{ item.name }}
              </div>
              <div v-else>
                --
              </div>
            </td>
            <td
              :class="item.status === 0 ? 'red lighten-4 pa-2' : 'white pa-2'"
            >
              <v-col>
                {{ item.email }}
              </v-col>
            </td>
            <td
              :class="item.status === 0 ? 'red lighten-4 pa-2' : 'white pa-2'"
            >
              <div v-if="item.phone">
                {{ item.phone }}
              </div>
              <div v-else>
                --
              </div>
            </td>
            <td
              :class="item.status === 0 ? 'red lighten-4 pa-2' : 'white pa-2'"
            >
              <div v-for="(r, i) in item.real_estate_develop_id" :key="i">
                <div v-if="getProjectsById(r)">
                  {{ getProjectsById(r).name }}
                </div>
              </div>
            </td>
            <td
              :class="item.status === 0 ? 'red lighten-4 pa-2' : 'white pa-2'"
            >
              <v-col>
                <v-checkbox
                  v-if="item.status === 0 || item.status === 3"
                  :input-value="item.status === 3"
                  color="indigo darken-3"
                  disabled
                  light
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-else
                  :input-value="item.status === 1"
                  color="indigo darken-3"
                  light
                  hide-details
                  @change="changeDisponibility(item, item.status)"
                ></v-checkbox>
              </v-col>
            </td>
            <td
              :class="item.status === 0 ? 'red lighten-4 pa-2' : 'white pa-2'"
            >
              <div v-if="item.performance.totalLeads" class="text-center">
                {{ item.performance.totalLeads }}
              </div>
              <div v-else>
                --
              </div>
            </td>
            <td
              :class="
                item.status === 0
                  ? 'red lighten-4 pa-2'
                  : 'white pa-2 text-center'
              "
            >
              {{ item.performance.finished }}
            </td>
            <td
              :class="
                item.status === 0
                  ? 'red lighten-4 pa-2'
                  : 'white pa-2 text-center'
              "
            >
              {{ item.performance.discarded }}
            </td>
            <td
              :class="
                item.status === 0
                  ? 'red lighten-4 pa-2'
                  : 'white pa-2 text-center'
              "
            >
              {{ item.performance.active }}
            </td>
            <td
              :class="
                item.status === 0
                  ? 'red lighten-4 pa-2'
                  : 'white pa-2 text-center'
              "
            >
              {{ item.performance.defeated }}
            </td>
            <td
              :class="item.status === 0 ? 'red lighten-4 pa-2' : 'white pa-2'"
            >
              <v-progress-linear
                :value="getPorcentsLead(item.performance)"
                rounded
                height="20"
              >
                <strong>{{ getPorcentsLead(item.performance) }}%</strong>
              </v-progress-linear>
            </td>
            <td
              :class="item.status === 0 ? 'red lighten-4 pa-2' : 'white pa-2'"
            >
              <div v-if="item.role" class="mx-2">
                {{ item.role }}
              </div>
              <div v-else class="mx-2">
                --
              </div>
            </td>
            <td
              :class="item.status === 0 ? 'red lighten-4 pa-2' : 'white pa-2'"
            >
              <v-row>
                <v-tooltip bottom>
                  <template #activator="{ on: tooltip }">
                    <v-btn
                      icon
                      v-on="{ ...tooltip }"
                      @click="editContact(item, true)"
                    >
                      <v-icon light icon color="primary">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                      <v-icon light icon color="primary">
                        mdi-dots-horizontal
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(option, x) in options(item)" :key="x">
                      <v-btn
                        block
                        text
                        :class="`${option.textColor}--text`"
                        @click="
                          option.title === 'Detalles'
                            ? showDetails(item)
                            : option.title === 'Restablecer Contraseña'
                            ? restorePassword(item)
                            : confirmDeleteBroker(item, 'Active')
                        "
                      >
                        {{ option.title }}
                        <v-spacer></v-spacer>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <restore-password-contact-modal
      :visible="showRestoreContactModal"
      :contact-to-restore="contactToRestore"
      @close="onCloseRestoreContactModal"
    />
    <delete-contact-modal
      ref="deleteContactModal"
      :visible="showDeleteContactModal"
      :contact-to-delete="contactToDelete"
      @close="onCloseDeleteContactModal"
    />
    <update-contact-modal
      ref="updateContactModal"
      :visible="showUpdateContactModal"
      @close="onCloseUpdateContactModal"
    />
    <add-contact-modal
      ref="addContactModal"
      :visible="showAddContactModal"
      :real-estate="realEstateList"
      @close="onCloseAddContactModal"
    />
    <details-contact-modal
      ref="detailsContactModal"
      :contact-to-show-details="contactToShowDetails"
      :real-estate="realEstateList"
      @close="onCloseDetailsContactModal"
    />
    <!-- <create-massive /> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  components: {
    // CreateMassive: () => import("@/components/lead/CreateMassiveLead.vue"),
    DeleteContactModal: () => import("@/components/contacts/DeleteContact.vue"),
    RestorePasswordContactModal: () =>
      import("@/components/contacts/RestorePasswordContact.vue"),
    UpdateContactModal: () => import("@/components/contacts/UpdateContact.vue"),
    AddContactModal: () => import("@/components/contacts/AddContact.vue"),
    DetailsContactModal: () =>
      import("@/components/contacts/DetailsContact.vue")
  },
  data() {
    return {
      showUpdateContactModal: false,
      showAddContactModal: false,
      showDeleteContactModal: false,
      showRestoreContactModal: false,
      contactToShowDetails: null,
      contactToEdit: null,
      contactToDelete: null,
      contactToRestore: null,
      headers: [
        {
          text: "Foto",
          value: "media.featured_image.src",
          sortable: false
        },
        {
          text: "Asesor",
          value: "name",
          sortable: false
        },
        {
          text: "Correo",
          value: "email",
          sortable: false
        },
        {
          text: "Telefono",
          value: "phone",
          sortable: false
        },
        {
          text: "Proyecto",
          value: "realEstate",
          sortable: false
        },
        {
          text: "Status",
          value: "status",
          sortable: false
        },
        {
          text: "Prospectos",
          value: "performance.totalLeads",
          sortable: false
        },
        {
          text: "Finalizados",
          value: "performance.finished",
          sortable: false
        },
        {
          text: "Descartados",
          value: "performance.discarded",
          sortable: false
        },
        {
          text: "Atendidos",
          value: "performance.active",
          sortable: false
        },
        {
          text: "R. Atencion",
          value: "performance.defeated",
          sortable: false
        },
        {
          text: "% de desempeño",
          value: "performance",
          sortable: false
        },
        {
          text: "Rol",
          value: "role",
          sortable: false
        },
        {
          text: "Acciones",
          value: "",
          sortable: false
        }
      ],
      // options: [
      //   {
      //     title: "Detalles",
      //     textColor: "black"
      //   },
      //   {
      //     title: "Restablecer Contraseña",
      //     textColor: "black"
      //   },
      //   {
      //     title: "Eliminar Asesor",
      //     textColor: "red"
      //   }
      // ],
      isLoading: true,
      loadingStatus: "Obteniendo Asesores ...",
      statusContactsList: 0,
      contactsList: [],
      realEstateList: [],
      contactAllocation: [],
      porcents: 0
    };
  },
  computed: {
    ...mapState({
      contactsListLoadingSt: state => state.contacts.ContactsListLoading,
      contactListSt: state => state.contacts.contactList,
      contactListAllocationSt: state => state.contacts.contactListAllocation
    })
  },
  mounted() {
    this.getContactsList();
  },
  methods: {
    ...mapActions({
      fetchContactStore: "contacts/fetchContactList",
      fetchContactAllocationStore: "contacts/fetchContactListAllocation",
      updateContactAllocationStore: "contacts/updateContactListAllocation",
      fetchRealEstateListStore:
        "realEstateDevelopments/getRealEstateDevAssgign",
      updateContactByIdStore: "contacts/updateContactById"
    }),
    options(item) {
      const userToken = localStorage.getItem("token");
      const payload = JSON.parse(atob(userToken.split(".")[1]));
      if (payload.sub === item.user_id) {
        return [
          {
            title: "Detalles",
            textColor: "black"
          },
          {
            title: "Restablecer Contraseña",
            textColor: "black"
          }
        ];
      } else if (item.status === 0) {
        return [
          {
            title: "Detalles",
            textColor: "black"
          },
          {
            title: "Restablecer Contraseña",
            textColor: "black"
          },
          {
            title: "Eliminar Asesor",
            textColor: "red"
          }
        ];
      } else {
        return [
          {
            title: "Detalles",
            textColor: "black"
          },
          {
            title: "Restablecer Contraseña",
            textColor: "black"
          },
          {
            title: "Eliminar Asesor",
            textColor: "red"
          }
        ];
      }
    },
    showAddContactModalF() {
      this.$refs.addContactModal.setAllLocation(
        this.contactListAllocationSt.response.data.Allocation
      );
      this.showAddContactModal = true;
    },
    editContact(item, val) {
      this.$refs.updateContactModal.setContactUpdate(item);
      this.$refs.updateContactModal.setRealEstate(this.realEstateList);
      this.$refs.updateContactModal.setAllLocation(
        this.contactListAllocationSt.response.data.Allocation
      );
      this.showUpdateContactModal = val;
    },
    onCloseUpdateContactModal(newData) {
      let tmpList = [];
      if (newData.user !== null) {
        let index = this.contactsList.findIndex(function(items) {
          return items._id == newData.user.contact_id;
        });
        this.contactsList[index].name = newData.user.name;
        this.contactsList[index].email = newData.user.email;
        //this.contactsList[index].phone = newData.user.phone;
        //this.contactsList[index].real_estate_develop_id =
        //newData.user.real_estate_develop_id;
        tmpList = this.contactsList;
      } else {
        tmpList = this.contactsList;
      }
      this.contactsList = tmpList;
      this.contactToEdit = null;
      this.showUpdateContactModal = false;
    },
    onCloseAddContactModal(newData) {
      if (newData !== null) {
        this.getContactsList();
        this.showAddContactModal = false;
      }
      this.showAddContactModal = false;
    },
    onCloseDetailsContactModal() {
      this.contactToShowDetails = null;
    },
    onCloseDeleteContactModal(data) {
      if (data) {
        this.deleteBroker(this.contactToDelete);
      } else {
        this.showDeleteContactModal = false;
        this.contactToDelete = null;
      }
    },
    onCloseRestoreContactModal() {
      this.showRestoreContactModal = false;
      this.contactToRestore = null;
    },
    getContactsList() {
      this.fetchContactStore().then(res => {
        this.sortListByStatus(res.ContactsList);
        this.getRealEstateList();
      });
    },
    sortListByStatus(list) {
      let tmpList = list.filter(item => {
        return item.status !== 0 || item.performance.totalLeads >= 1;
      });

      this.contactsList = tmpList.sort((a, b) => {
        return a.status - b.status;
      });
    },
    getProfileImg(media) {
      if (media && media.hasOwnProperty("featured_image")) {
        if (media.featured_image.hasOwnProperty("src")) {
          if (
            media.featured_image.src !== null &&
            media.featured_image.src !== ""
          ) {
            return media.featured_image.src;
          } else {
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
          }
        } else if (media.featured_image.hasOwnProperty("src_avatar")) {
          if (
            media.featured_image.src_avatar !== null &&
            media.featured_image.src_avatar !== ""
          ) {
            return media.featured_image.src_avatar;
          } else {
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
          }
        } else {
          return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
        }
      } else {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
      }
    },
    changeDisponibility(item, value) {
      let index = this.contactsList.findIndex(function(items) {
        return items._id == item._id;
      });
      this.contactsList[index].status = value === 1 ? 2 : 1;
      this.updateContactByIdStore({
        role: "admin",
        data: {
          _id: item._id,
          table: item.role,
          role: "admin",
          status: value === 1 ? 2 : 1
        }
      });
    },
    getRealEstateList() {
      this.loadingStatus = "Obteniendo Desarrollos";
      this.fetchRealEstateListStore()
        .then(t => {
          this.realEstateList = t.response.data.RealEstateDevelopment;
          this.getContactAllocation();
        })
        .catch(function() {
          this.realEstateList = [];
          this.isLoading = false;
        });
    },
    getContactAllocation() {
      this.loadingStatus = "Obteniendo Tablas de asignación";
      this.fetchContactAllocationStore()
        .then(t => {
          this.contactAllocation = t.response.data.Allocation;
          this.isLoading = false;
        })
        .catch(function() {
          this.contactAllocation = [];
          this.isLoading = false;
        });
    },
    getProjectsById(id) {
      return this.realEstateList.find(r => {
        return r._id === id;
      });
    },
    showDetails(item) {
      this.$refs.detailsContactModal.setContactToShowDetails(item);
    },
    restorePassword(item) {
      this.showRestoreContactModal = true;
      this.contactToRestore = item;
    },
    confirmDeleteBroker(item) {
      this.showDeleteContactModal = true;
      this.contactToDelete = item;
      this.$refs.deleteContactModal.setContactToDelete(item);
      this.$refs.deleteContactModal.fechtLeadsById(item);
    },
    deleteBroker(item) {
      let index = this.contactsList.findIndex(function(items) {
        return items._id === item._id;
      });
      let list = this.contactsList[index].real_estate_develop_id;
      if (list) {
        list.forEach(e => {
          this.deleteFrommAllocation(this.contactsList[index], e);
        });
      }
      this.contactsList[index].status = 0;
      this.sortListByStatus(this.contactsList);
      this.showDeleteContactModal = false;
      this.contactToDelete = null;
      this.updateContactByIdStore({
        role: "admin",
        data: {
          _id: item._id,
          table: item.role,
          role: "admin",
          status: 0,
          real_estate_develop_id: []
        }
      });
    },
    deleteFrommAllocation(item, idAllocation) {
      const self = this;
      let index = this.contactAllocation.findIndex(t => {
        return t.real_estate_develop_id == idAllocation;
      });
      if (index > 0) {
        let list = self.contactAllocation[index].contact_list.filter(l => {
          return l.idContact !== item._id;
        });
        if (list) {
          self
            .updateContactAllocationStore({
              idAllocation: this.contactAllocation[index]._id,
              real_estate_group_id: this.contactAllocation[index]
                .real_estate_group_id,
              name: this.contactAllocation[index].name,
              contact_list: list,
              index_position: this.contactAllocation[index].index_position,
              last_index_assigned: this.contactAllocation[index]
                .last_index_assigned,
              old_index_assigned: this.contactAllocation[index]
                .old_index_assigned
            })
            .then(() => {
              this.isUpdating = false;
              this.tabledUpdatingId = "";
            });
        }
      }
    },
    getPorcentsLead(performance) {
      // return (this.porcents = Math.round(
      //   (performance.total - performance.defeated) * 100
      // ));
      let total = performance.total;
      let active = performance.active;
      let final = total === 0 ? 0 : Math.round((active * 100) / total);
      return final;
      // return this.porcents = Math.round( 100 - (performance.total*(performance.defeated/100)))
    }
  }
};
</script>

<style lang="scss" scoped></style>
